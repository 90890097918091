<template>
  <div class="container mt-4 mb-3 terms">
    <div class="row">
      <div class="col-12">
        <h1>Política de Privacidade</h1>
        <p>
          A nossa política de privacidade foi criada para informar o cliente no que respeita à recolha, utilização e divulgação de dados pessoais que possa fornecer neste site. 
          <br>
          Ao utilizar este website, está a concordar com os termos da nossa Política de Privacidade. 
          <br>
          <b>Os seus dados</b>
          <br>
          Alguns formulários neste website, podem solicitar que submeta dados de informação pessoal, de forma a que possa realizar determinados pedidos, tais como efetuar reservas, submeter formulário de contacto ou até mesmo inscrever-se na nossa newsletter.
          <br>
          <b>Recolha de Dados Pessoais</b>
          <br>
          Ao navegar neste website, determinadas informações podem ser recolhidas de forma anónima, isto é sem que tenha introduzido ou submetido estes dados, por via de tecnologia como cookies, beacons e ficheiros que registam a sequência de como navega neste website. O seu browser de internet transmite este dados automaticamente para este website, algumas destas informações são URL que deu origem da sua visita a este website, IP (Endereço Protocolo de Internet), a versão do seu browser que utiliza e o dispositivo (PC, Tablet ou Smartphone), poderá configurar o seu browser para recusar todos os cookies ou beacons, mas determinadas funcionalidades podem não funcionar com os cookies desativados. Ao navegar nosso website está a autorizar a recolha destes dados, se não estiver de acordo com a recolha desta informação deverá abandonar o nosso website, ou consultar a nossa 
        </p>
        <h2>Política de Cookies para saber como desativar a recolha de informação anónima.</h2>
        <p>
          <b>Utilização e Divulgação de Dados Pessoais</b>
          <br>
          Salvo por indicação expressa em contrário, podemos utilizar os seus dados submetidos neste website para melhor a sua experiência de navegação, para lhe informar das nossas melhores ofertas e de novos serviços (se subscrever a nossa newsletter), com finalidade publicitária e informativa e para qualquer outro fim especificado. Alguns dos seus dados pessoais recolhidos neste website podem ser guardados e agregados por ordem alfabética e de forma segura e com acesso limitado. Podemos fazer uso total da informação por nós recolhida através deste website e também partilhar dados recolhidos através deste website com terceiros, como sistema de reservas, fornecedores, serviços de atendimento e suporte técnico. Ao ceder informação a terceiros, estes estão obrigados por lei a proteger e a informá-lo da recolha e utilização destes mesmo dados e com que finalidade, como por exemplo responder a pedidos de execução da lei ou, onde necessário, pelas leis aplicáveis.
          <br>
          <b>Acesso, Correcção e Eliminação de Dados Pessoais</b>
          <br>
          Para solicitar acesso, correcção ou eliminação de Dados Pessoais recolhidos por este website deverá entrar em contacto por email para geral@lubrirent.pt. 
        </p>
        <h2>Segurança</h2>
        <p>
         Este website utiliza certificado digital SSL de forma a proteger os dados submetidos, é desenhado sobre a mais recentes tecnologias de forma a proteger a perda, má utilização, alteração, divulgação, destruição ou acesso não autorizado por terceiros. Deverá estar informado que nenhuma interação na internet é completamente segura ou livre de erros
        <br>
        <b>Os Nossos Contactos:</b>
        <br>
        Sempre que tenha dúvidas ou deseja fazer comentários sobre esta Política de Privacidade ou utilização deste website, poderá nos pela seguinte morada:
        <br>
        Lubrirent <br>
        Rua Pinhal Cordeiro - Carvoeiros <br>
        2420-500 Leiria <br>
        Portugal <br>
        Telefone:( 351) 244830000 / Email: geral@lubrirent.pt
        </p>
        <h2>Alterações à Política de Privacidade</h2>
        <p>
          Sempre que efetuadas alterações à presente Política de Privacidade, as mesmas serão atualizadas sem qualquer tipo de aviso prévio ou indicação, por isso é aconselhável que sempre que utilizar este website consulte a nossa Política de Privacidade, de forma a ter total conhecimento da mesma.
        </p>
        <h2>POLÍTICA DE PROTEÇÃO DE DADOS (RGPD)</h2>
        <p>
          Para nós a privacidade dos seus dados pessoais é muito importante, por isso respeitamos o Regulamento Geral de Proteção de Dados que entrou em vigor em Portugal no dia 25 de Maio de 2018. 
        </p>
        <p>
        Para tal iremos descrever nas alíneas abaixo mencionadas, de que forma tratamos os seus dados pessoais:
        <br>
        a) Finalidade do tratamento dos seus dados pessoais:
        <br>
        Manter uma relação contratual e de prestação de serviços prestados pela Lubrirent ou por terceiros aos nossos clientes;
        <br>
        Para contacto com os nossos clientes através dos canais como telefone, e-mail ou correspondência postal;
        <br>
        Para campanhas de Marketing, Promoções, Novos Serviços, entre outros que consideremos ser do interesse dos nossos clientes;
        <br>
        Para realização de inquéritos de qualidade a serviços prestados pela Lubrirent
        </p>
        <p>
         b) Base Legítima do Tratamento dos Dados:
        <br>
        O tratamento legítimo dos Dados Pessoais é estabelecido por uma relação contratual ou pré-contratual estabelecida com os nossos clientes, bem como o consentimento concedido e, na sua ausência, o interesse legítimo pelo envio de informação que consideramos ser do seu total interesse.
        </p>
        <p>
          c) Comunicação a Terceiros e Confidencialidade:
          <br>
          Tenha em atenção que a Lubrirent só comunicará dados pessoais a terceiros sempre que os mesmos sejam necessários à prestação de serviços, como por exemplo sistema de reservas online, de outra forma não comunicamos os seus dados a terceiros e os mesmos serão mantidos e tratados com absoluta confidencialidade.
        </p>
        <p>
          d) Conservação de Dados Pessoais:
          <br>
          A Lubrirent irá conservar os dados fornecidos neste website enquanto mantiver relação contratual ou pré-contratual que motivou a recolha e tratamento destes mesmos dados, durante o tempo necessário para cumprir obrigações legais a que está sujeito por lei, e enquanto o titular não exerça o direito de supressão ou oposição ao tratamento dos mesmos.
        </p>
        <p>
          e) Direitos dos Titulares dos Dados:
          <br>
          Os titulares dos Dados Pessoais, têm direito ao acesso, rectificação, exclusão, oposição, limitação ao tratamento, portabilidade e a registar reclamações, de acordo com os seus direitos, os mesmo deverão ser dirigidos diretamente à entidade que controla os respetivos dados, para poder exercer os seus direitos, poderá enviar um email para: geral@lubrirent.pt
          <br>
          Caso não seja do seu interesse receber informações comerciais da Lubrirent, poderá enviar uma email para: geral@lubrirent.pt onde no assunto deve mencionar “remover da newsletter”.
          <br>
          Para qualquer esclarecimento adicional relacionado com o nosso tratamento de dados, contacte-nos pelo seguinte email:  geral@lubrirent.pt
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  components: {},
  data: function () {
    return {};
  },
};
</script>
